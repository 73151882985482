$(document).ready(function() {
	tinyLayer.initModule('.swatch, .flag-icon .flag-item'); //PJTWAUS-1 : 20200113 add
	if(!document.querySelector('.js-model-switcher')) return false;
	var modelSwicher = {
		el: document.querySelectorAll('.js-model-switcher'),
		currentEl: null,
		formId: 'sendSiblingModelForm',
		subModelId: 'subModelId',
		itemSelector: '.js-model',
		$items: null,
		ajaxUrl: null,
		paramName: null,
		bizType:'B2C',
		init: function(){
			if($('.navigation').length>0) {
				if($('.navigation').hasClass('b2b')) this.bizType = 'B2B';
			}
			//console.log(this.bizType);
			for (var i = 0; i < modelSwicher.el.length; i++) {
				modelSwicher.currentEl = modelSwicher.el[i];
				modelSwicher.$items = $(modelSwicher.currentEl).find(modelSwicher.itemSelector);
				modelSwicher.ajaxUrl = modelSwicher.currentEl.getAttribute('data-model-url');
				modelSwicher.paramName = modelSwicher.currentEl.getAttribute('data-name');
				modelSwicher.addEvent();
			}
		},
		numberWithCommas: function(num) {
			if(num==0) return 0;
			num = parseFloat(num);
			if(isNaN(num)) return 0;
			var reg = /(^[+-]?\d+)(\d{3})/;
			var n = (num + '');
			while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
			return n;
		},
		decimalNumber: function(num) {
			if(!num) num="00";
			return num;
		},
		addEvent: function(){
			$(modelSwicher.currentEl).on({
				click: function(e){
					e.preventDefault();
					var modelNumber = (e.currentTarget.getAttribute('href').indexOf('#')>-1) ? e.currentTarget.getAttribute('data-href') : e.currentTarget.getAttribute('href'),
						groupIndex = $(e.currentTarget).index();
						methodType = $(modelSwicher.currentEl).data('ajaxMethod'),
						param = {},
						ajaxUrl = $(e.currentTarget).closest(".js-model-switcher").attr("data-model-url");

					param[modelSwicher.paramName] = modelNumber;
					param["bizType"] = modelSwicher.bizType;

					// for search result
					if($('.search-contents-area').length>0) {
						if($(e.currentTarget).closest('.business-product-list').length>0) {
							param["bizType"] = 'B2B';
						} else {
							param["bizType"] = 'B2C';
						}
					}

					if($(e.currentTarget).closest('.C0007').length>0) {
						param["categoryId"] = $(e.currentTarget).closest('.C0007').find('form input[name=categoryId]').val();
					}

					ajax.call(ajaxUrl, param, 'json', function(data){
						if(data && data != '') {
							var $item = $(e.currentTarget).closest(modelSwicher.itemSelector);
							if(data.status=='success') {
								var d = data.data[0];
								// change tag
								$item.find('.tag-content').empty();
								if(d.productTag1 != null && d.productTag1 != '') $item.find('.tag-content').append('<span>'+d.productTag1+'</span>');
								if(d.productTag2 != null && d.productTag2 != '') $item.find('.tag-content').append('<span>'+d.productTag2+'</span>');
								// change image and link
								$item.find('a.visual').attr('href', d.modelUrlPath);
								if(d.imageAltText != null && d.imageAltText != '') {
									$item.find('a.visual img').attr('alt', d.imageAltText);
								} else {
									$item.find('a.visual img').attr('alt', '');
								}
								bindImgError();
								$item.find('a.visual img').attr('aria-hidden','true'); // LGEUS-930 20200917 modify
								$item.find('a.visual img.pc').attr('src', d.mediumImageAddr).attr('data-src', d.mediumImageAddr);
								$item.find('a.visual img.mobile').attr('src', d.smallImageAddr).attr('data-src', d.smallImageAddr);
								if(d.modelRollingImgList != null && d.modelRollingImgList != '') {
									$item.find('a.visual img.pc').addClass('js-thumbnail-loop').attr('data-img-list', d.modelRollingImgList)
								} else {
									$item.find('a.visual img.pc').removeClass('js-thumbnail-loop').removeAttr('data-img-list');
								}
								// remove 
								$item.find('.thumbnail-carousel').remove();
								if(thumbnailLoop) thumbnailLoop.mobileThumbnailCarouselSingle($item.find('a.visual img[data-img-list]').eq(0), true);
								// change sibling link
								$item.find('.model-group a').removeClass('active').attr('aria-checked', false).eq(groupIndex).addClass('active').attr('aria-checked', true); /* PJTWAUS-1 : 20200113 modify */
								// change model name and link
								$item.find('.products-info .model-name a').attr('href', d.modelUrlPath).html(d.userFriendlyName);
								if(d.modelUrlPath == null) {
									$item.find('a.visual').removeAttr('href');
									$item.find('.products-info .model-name a').removeAttr('href');
								}
								if(d.userFriendlyName == null) {
									$item.find('.products-info .model-name a').html(d.userFriendlyName);
								}
								// change sku
								$item.find('.products-info .sku').html(d.modelName);
								// change rebate
								$item.find('.ecorebates-div').attr('data-modelId', d.modelId);
								// rating - If target is self, do not change the bv area
								if(modelSwicher.bizType=="B2C" && d.target && d.target.toUpperCase() == "NEW") {
									$item.find('.rating').replaceWith('<div class="rating" data-bv-show="inline_rating" data-bv-product-id="'+d.modelId+'" data-bv-redirect-url="'+d.modelUrlPath+'#pdp_review"></div>');
								}
								// Price
								var currencyUnit = "$";
								var discountRateText = " Off";
								var discountPriceText = "Save ";
								$item.find('.price-area').empty();
								if(d.modelStatusCode=='DISCONTINUED') {
									// do nothing
								/* DC-680 : 20210526 modify 
								 * if d.obsCondition == Y then check if there is a value between rPromoPrice and rPrice. If there is a value, print it 
								 * */ 
								/* LGEUS-577 : 20200526 modify */
								/* LGEUS-12083 : 20190826 modify */
								} else if(d.obsCondition == 'Y') {
									if (d.rPromoPrice != null && d.rPromoPrice != '' 
									&& d.rPromoPriceCent != null && d.rPromoPriceCent != '' 
									&& d.discountedRate != null && d.discountedRate != '' 
									&& d.rDiscountedPrice !=null && d.rDiscountedPrice != '' 
									&& d.rDiscountedPriceCent !=null && d.rDiscountedPriceCent != '' 
									&& d.rPrice != null && d.rPrice != '' ) {
										$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPromoPrice)+'.'+d.rPromoPriceCent+'</div></div><div class="product-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+modelSwicher.decimalNumber(d.rPriceCent)+'</div><div class="legal">'+discountPriceText+' '+currencyUnit+modelSwicher.numberWithCommas(d.rDiscountedPrice)+'.'+modelSwicher.decimalNumber(d.rDiscountedPriceCent)+'</div></div>');
									}else if(d.rPrice != null && d.rPrice != '') {
										$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+modelSwicher.decimalNumber(d.rPriceCent)+'</div></div>');
									}
								} else if(d.retailerPricingFlag == "Y") {
									$item.find('.price-area').append('<div class="retailer">'+d.retailerPricingText+'</div>');
								} else if( d.rPromoPrice != null && d.rPromoPrice != '' 
									&& d.rPromoPriceCent != null && d.rPromoPriceCent != '' 
									&& d.discountedRate != null && d.discountedRate != '' 
									&& d.rDiscountedPrice !=null && d.rDiscountedPrice != '' 
									&& d.rDiscountedPriceCent !=null && d.rDiscountedPriceCent != '' 
									&& d.rPrice != null && d.rPrice != '' ) { 
										$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPromoPrice)+'.'+d.rPromoPriceCent+'</div></div><div class="product-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+modelSwicher.decimalNumber(d.rPriceCent)+'</div><div class="legal">'+discountPriceText+' '+currencyUnit+modelSwicher.numberWithCommas(d.rDiscountedPrice)+'.'+modelSwicher.decimalNumber(d.rDiscountedPriceCent)+'</div></div>'); 
								/* //LGEUS-12083 : 20190826 modify */
								/* //LGEUS-577 : 20200526 modify */
								} else if(modelSwicher.bizType=="B2B" && d.rPrice != null && d.rPrice != '') {
									$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+modelSwicher.decimalNumber(d.rPriceCent)+'</div></div>');
								} else if(modelSwicher.bizType=="B2C" && d.rPrice != null && d.rPrice != '') {
									$item.find('.price-area').append('<div class="purchase-price"><div class="price">'+currencyUnit+modelSwicher.numberWithCommas(d.rPrice)+'.'+modelSwicher.decimalNumber(d.rPriceCent)+'</div></div>');
								/* DC-680 : 20210526 modify */
								}

								// Promotion text
								if(d.promotionText != null && d.promotionText != '') $item.find('.promotion-text').html(d.promotionText);
								else $item.find('.promotion-text').html('');
								// Button (add to cart)
								if (d.addToCartFlag=="Y") {
									$item.find('.button a.add-to-cart').addClass('active').data('model-id', d.modelId);
								} else {
									$item.find('.button a.add-to-cart').removeClass('active');
								}

								// Button (where to buy and preorder)
								if(d.whereToBuyFlag=="Y" && d.whereToBuyUrl != null && d.whereToBuyUrl != ''){
									   $item.find('.button a.where-to-buy').addClass('active').attr('href', d.whereToBuyUrl);
									   $item.find('.button a.preorder').removeClass('active');
									 //LGEUS-359 Start
							    }else if(d.wtbExternalLinkUseFlag=="Y" &&  d.wtbExternalLinkUrl && d.wtbExternalLinkName){
							    	if(d.wtbExternalLinkName == "Preorder" || d.wtbExternalLinkName == "Pre-Order") {
							    		$item.find('.button a.preorder').addClass('active').attr('href', d.wtbExternalLinkUrl);
							    		$item.find('.button a.where-to-buy').removeClass('active');
							    	} else {
							    		$item.find('.button a.preorder').removeClass('active');
							    		$item.find('.button a.where-to-buy').addClass('active').attr('href', d.wtbExternalLinkUrl);
							    	}
								//LGEUS-359 End	
							    }else {
							    	$item.find('.button a.where-to-buy').removeClass('active');
							    	$item.find('.button a.preorder').removeClass('active');
								}
								
								if(d.findTheDealerFlag=="Y" && d.findTheDealerUrl != null && d.findTheDealerUrl != '') $item.find('.button a.find-a-dealer').addClass('active').attr('href', d.findTheDealerUrl);
								else $item.find('.button a.find-a-dealer').removeClass('active');
								if(d.inquiryToBuyFlag=="Y" && d.inquiryToBuyUrl != null && d.inquiryToBuyUrl != '') $item.find('.button a.inquiry-to-buy').addClass('active').attr('href', d.inquiryToBuyUrl);
								else $item.find('.button a.inquiry-to-buy').removeClass('active');
								// compare
								$item.find('.wishlist-compare .js-compare').attr('data-model-id', d.modelId);
								//PJTUSPDPUX-1
								if(getCookie(compareCookie.name) != undefined) {
									if(d.modelId, getCookie(compareCookie.name).indexOf(d.modelId) != -1) {
										// If compare cookie has this product
										$item.find('.wishlist-compare .js-compare').addClass('added').prop("checked",true);
									} else {
										$item.find('.wishlist-compare .js-compare').removeClass('added').prop("checked",false);
									}
								} else {
									$item.find('.wishlist-compare .js-compare').removeClass('added').prop("checked",false);
								}
								//PJTUSPDPUX-1
								// sibling target check
								if(d.target && d.target.toUpperCase() == "SELF") {
									$item.addClass('self');
								}else {
									$item.removeClass('self');
								}
								// adobe
								// LGEUS-188
								if(d.modelName != null && d.modelName != '' && d.modelName != "undefined") {
									$item.find('a.visual').data('adobe-modelname', d.modelName);
									$item.find('.ga-model-info').data('ga-modelname', d.modelName);
									$item.find('.ga-model-info').attr('data-ga-modelname', d.modelName);
								} else {
									$item.find('a.visual').data('adobe-modelname', '');
									$item.find('.ga-model-info').data('ga-modelname', '');
									$item.find('.ga-model-info').attr('data-ga-modelname', '');
									
								}
								if(d.salesModelCode != null && d.salesModelCode != '' && d.salesModelCode != "undefined") {
									$item.find('a.visual').data('adobe-salesmodelcode', d.salesModelCode);
									$item.find('.ga-model-info').data('ga-salesmodelcode', d.salesModelCode);
									$item.find('.ga-model-info').attr('data-ga-salesmodelcode', d.salesModelCode);
								} else {
									$item.find('a.visual').data('adobe-salesmodelcode', '');
									$item.find('.ga-model-info').data('ga-salesmodelcode', '');
									$item.find('.ga-model-info').attr('data-ga-salesmodelcode', '');
								}
								if(d.buName1 != null && d.buName1 != '' && d.buName1 != "undefined") {
									$item.find('.ga-model-info').data('bu-name', d.buName1);
									$item.find('.ga-model-info').attr('data-bu-name', d.buName1);
								} else {
									$item.find('.ga-model-info').data('bu-name', '');
									$item.find('.ga-model-info').attr('data-bu-name', '');
								}

								// LGEUS-188
								// ecorebate
								if($item.find('.rebate-box').length>0) {
									$item.find('.rebate-box').empty().html('<div class="product-rebate"><div class="ecorebates-div" data-modelId="'+d.modelId+'"></div></div>');
									if($.isFunction(runEcorebates)) runEcorebates();
								}
								// for compare product page
								if($('.compare-wrap').length>0 && $('.compare-sticky').length>0) {
									$item.find('.button').attr('data-model-id', d.modelId);
									// check that this product is in the Compare page
									var cookie = getCookie(compareCookie.name);
									if(cookie && cookie != '') {
										if(cookie.indexOf(d.modelId)==-1) {
											$item.find('.button a').eq(0).removeClass('active');
											$item.find('.button a').eq(1).addClass('active');
										} else {
											$item.find('.button a').eq(0).addClass('active');
											$item.find('.button a').eq(1).removeClass('active');
										}
									} else {
										$item.find('.button a').eq(0).removeClass('active');
										$item.find('.button a').eq(1).addClass('active');
									}
								}
							}
						}
					}, methodType);
				}
			}, modelSwicher.itemSelector+' .model-group a');

			var anchorList = [modelSwicher.itemSelector+'.self .model-name a', modelSwicher.itemSelector+'.self a.visual', modelSwicher.itemSelector+'.self a.btn.where-to-buy'];
			$(modelSwicher.currentEl).on({
				click: function(e){
					e.preventDefault();
					var form = document.getElementById(modelSwicher.formId),
						id = ($(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('href') != "#") ? $(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('href') : $(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('data-href');
					if(!form) {
						$('body').append("<form action='' id='"+modelSwicher.formId+"' method='post'><input type='hidden' name='"+modelSwicher.subModelId+"'></form>");
						form = document.getElementById(modelSwicher.formId);
					}
					form.action = e.currentTarget.href;
					form[modelSwicher.subModelId].value = id;
					if(e.ctrlKey || e.metaKey || e.shiftKey) form.target = '_blank';
					else form.target = '_self';
					form.submit();
				}
			}, anchorList.join(','));

			$('.js-model-switcher').on('click', '.js-model.self .rating a.bv_main_container', function(e) {
				e.preventDefault();
				var form = document.getElementById(modelSwicher.formId),
					id = ($(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('href') != "#") ? $(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('href') : $(e.currentTarget).closest(modelSwicher.itemSelector).find('.model-group .active').attr('data-href');
				if(!form) {
					$('body').append("<form action='' id='"+modelSwicher.formId+"' method='post'><input type='hidden' name='"+modelSwicher.subModelId+"'></form>");
					form = document.getElementById(modelSwicher.formId);
				}
				form.action = e.currentTarget.href;
				form[modelSwicher.subModelId].value = id;
				if(e.ctrlKey || e.metaKey || e.shiftKey) form.target = '_blank';
				else form.target = '_self';
				form.submit();
			});
		}
	};
	modelSwicher.init();
});